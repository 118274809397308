<template>
  <div>
    <Datatable ref="datatable" route="/projet/liste" :fields="fields" :data_filters="filters" titre="Liste des devis" :sort="sort">      
      <template #action>
        <b-button v-if="$api.acl(12)" variant="light-success" class="btn-icon" v-b-tooltip.hover title="Exporter votre rechercher" @click="toCSV()">
          <i class="fa fa-file-excel fa-size"></i>
        </b-button>
        <b-button variant="light-info" class="btn-icon ml-2" v-b-tooltip.hover title="Imprimer votre rechercher" @click="print()">
          <i class="fa fa-print fa-size"></i>
        </b-button>
        <b-dropdown v-if="$api.acl(13)" variant="light-danger" right v-b-tooltip.hover title="Envoyer un mail" no-caret class="ml-2" toggle-class="btn-icon" size="md">
          <template #button-content>
            <i class="fas" :class="loadMail ? 'fa-spin fa-spinner fa-size' : 'fa-envelope fa-size'"></i>
          </template>
          <b-dropdown-item v-for="(m,i) in mails" :key="i"  @click="tplMail = m.id; $refs.mailTpl.show()">{{m.titre}}</b-dropdown-item>
        </b-dropdown>
      </template>      
      <template #cell(action)="data">
        <b-form-checkbox v-model="selected" :key="data.item.id" :value="data.item" size="lg" inline >
          <i class="fa fa-star text-warning" v-if="checkSelect(data.item.id)"></i>
        </b-form-checkbox>              
      </template>              
      <template #cell(id)="data">
        <router-link :to="'/devis/detail/'+data.value">{{data.value}}</router-link>
      </template>
      <template #cell(nom)="data">
        <router-link :to="'/devis/detail/'+data.item.id">{{ data.value }} {{ data.item.prenom }}</router-link>
      </template>
      <template #cell(telephone_1)="data">
        <Telephone v-model="data.item.telephone_1" :edit="false" />
      </template>
      <template #cell(email)="data">
        <a :href="`mailto:${data.value}`">{{ data.value }}</a>
      </template>      
      <template #cell(regime_id)="data">
        <span>{{ $api.getParam('Regime', data.value).titre }}</span>
      </template>
      <template #cell(user.prenom)="data">
        <span>{{ $api.getParam('User', data.item.user.id).titre }}</span>
      </template>
      <template #cell(source_id)="data">
        <span>{{ $api.getParam('Source', data.value).titre }}</span>
      </template>      
      <template #cell(date_effet)="data">
        {{ $api.moment(data.value).format("DD/MM/YYYY") }}
      </template>            
      <template #cell(date_create)="data">
        {{$api.timestampToDate(data.value)}}
      </template>
      <template #cell(date_update)="data">
        {{$api.timestampToDate(data.value)}}
      </template>
      <template #cell(status_id)="data">
        <b-dropdown v-b-tooltip.hover title="Changer le statut du devis" no-caret :variant="$api.p('ProjetStatus', data.value).color" size="md">
          <template #button-content>
            {{ $api.p('ProjetStatus', data.value).titre }} <i class="ml-2 fas fa-chevron-down"></i>
          </template>
          <b-dropdown-item v-for="(s,i) in $api.params.ProjetStatus" :key="i" :disabled="$api.params.StatusLock.devis_lock.includes(data.value)" :active="data.value === s.id" @click="changeStatus(data.item, s.id)">{{s.titre}}</b-dropdown-item>
        </b-dropdown>        
      </template>
    </Datatable>
    <b-modal id="mailTpl" ref="mailTpl" hide-footer title="Envoi de mail en masse" size="lg" centered>
      <b-card-text v-if="tplMail != null">Vous etes sur le point d'envoyer le mail <strong>"{{ $api.p('Template', tplMail).titre }}"</strong> à <strong>{{ $refs.datatable.datatable.totalRow }}</strong> contacts</b-card-text>
      <div class="d-block text-center mt-3">
        <b-button variant="light-primary" :class="loadMail ? 'btn-icon' : ''" @click="sendMails()">
          <span v-if="loadMail === false" class="text-white">Envoyer</span>
          <i v-else class="fa fa-spin fa-spinner"></i>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
import Telephone from '@/components/Telephone';
export default {
  name: "ClientListe",
  components: {
    Datatable,Telephone
  },
  watch:{    
  },
  data(){
    return{            
      filters:{},   
      fields: [        
        {
          key: 'id',
          label: 'Ref',
          sortable:false,
        },        
        {
          key: 'nom',
          label: 'Nom',
          sortable:true,
        },
        {
          key: 'telephone_1',
          label: 'Telephone 1',
          sortable:false,
        },        
        {
          key: 'email',
          label: 'Email',
          sortable:false,
        },
        // {
        //   key: 'regime_id',
        //   label: 'Regime',
        //   sortable:true,
        // },
        {
          key: 'date_effet',
          label: 'Date effet',
          sortable:false,
        },        
        {
          key: 'user.prenom',
          label: 'Commercial',
          sortable:true,
        },
        // {
        //   key: 'source_id',
        //   label: 'Source',
        //   sortable:false,
        // },
        {
          key: 'date_create',
          label: 'Creation',
          sortable:false,
        },
        {
          key: 'date_update',
          label: 'Modification',
          sortable:false,
        },
        {
          key: 'status_id',
          label: 'Statut',
          sortable:false,
        },        
      ],      
      sort:{
        key: 'date_update',
        value: 'DESC'
      },
      loadMail:false,
      tplMail: null
    }
  },
  computed:{    
    mails(){
      var type = "ProjetMail";
      return this.$api.params.Template.filter(t => t.type === type);
    }
  },
  methods: {    
    changeStatus(data, status){
      data.status_id = status;
      var params = {
        id: data.id,
        status_id: status
      }
      this.$api.ajax('/projet/create', params, res => {
        if(res.status === true){
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
          //this.$refs.datatable.datatableInit();
        }
      })
    },
    sendMails(){
      if(this.loadMail === true){
        return true;
      }
      this.loadMail = true;
      var params = {
        tpl_id: this.tplMail,
        params: this.$refs.datatable.params
      }
      this.$api.ajax('/projet/mails', params, res => {
        if(res.status === true){
          this.$refs.mailTpl.hide();
          this.$bvToast.toast("L'envoi c'est correctement fini", {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
        }
        this.loadMail = false;
      });
    },
    print(){
      window.print();
    },
    toCSV(){
      var rows = [];
      var title = ['ID','Nom', 'Telephone 1', 'Email', 'Date effet', 'Commercial', 'Dae creation', 'Statut'];
      rows.push(title.join(';'));
      this.$refs.datatable.datatable.data.forEach((r) => {
        var tmp = [];
        tmp.push(r.id);
        tmp.push(r.nom);
        tmp.push(r.telephone_1);
        tmp.push(r.email);
        tmp.push(r.date_effet);
        tmp.push(this.$api.p('User', r.user_id).titre);          
        tmp.push(this.$api.p('Source', r.source_id).titre);          
        tmp.push(this.$api.moment(r.date_create * 1000).format("DD/MM/YYYY HH:mm:ss"));
        tmp.push(this.$api.p('ProjetStatus', r.status_id).titre);          
        rows.push(tmp.join(';'));        
      });
      rows = rows.join("\n");
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(rows);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Export.csv';
      hiddenElement.click();
    }
  },
  beforeMount() {
  },
  mounted() {         
  }
}
</script>
<style>
</style>
